const Contactus = () => {
  return (
    <address>
      <h2>
        Email us @ <a href="mailto:getandgain110524@gmail.com">Get and Gain</a>
      </h2>
    </address>
  );
};
export default Contactus;
